import React from "react";
import './App.css';
import { useMediaQuery } from 'react-responsive';

// Components
import { Desktop } from "./components/desktop/desktop.component";
import { Laptop } from "./components/laptop/laptop.component";
import { BigScreen} from "./components/big-screen/big-screen.component";
import { Mobile } from "./components/mobile/mobile.component";
import { TabletMobile } from "./components/tablet-mobile/tablet-mobile.component";
import { Layout } from './Layout';

export const Home = (props) => {
  const isMobileDevice = useMediaQuery({
    query: "(min-width: 200px) and (max-width: 500px)",
  });

  const isTabletDevice = useMediaQuery({
    query: "(min-width: 501px) and (max-width: 900px)",
  });

  const isLaptop = useMediaQuery({
    query: "(min-width: 901px) and (max-width: 1100px)",
  });

  const isDesktop = useMediaQuery({
    query: "(min-width: 1101px) and (max-width: 1200px)",
  });

  const isBigScreen = useMediaQuery({
    query: "(min-width: 1201px )",
  });

  return (

    <div className="App">

       {isMobileDevice && <Mobile user={props.user}/>}

       {isTabletDevice && <TabletMobile user={props.user}/>}

       {isDesktop && <Desktop user={props.user}/>}

       {isLaptop && <Laptop user={props.user}/>}

       {isBigScreen && <BigScreen user={props.user}/>}

    </div>
  );
}
