import React, { useState } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import uuid from 'react-uuid';
import { instanceOf } from 'prop-types';
import { withCookies, Cookies } from 'react-cookie';
import { useCookies } from 'react-cookie';

import Header from "./Header";
import AnswerCard from "./AnswerCard";
import Status from "./Status";
import {EndQuiz} from "./EndQuiz";
import './container.css';

class DesktopC extends React.Component {

    static propTypes = {
        cookies: instanceOf(Cookies).isRequired
    };

    constructor(props) {
        super(props);

        this.state = {
            questionNumber: 0,
            numCorrect: 0,
            statusShown: false,
            currentQuestionCorrect: false,
            qdata: []
        };
        this.questions = [];
        this.stats = [];
        this.userid = props.user;
        this.qsetid = '';
        this.handleClick = this.handleClick.bind(this);
    }


   componentWillMount() {


	        fetch('https://api.cinebotz.com/api/getbotq')
                    .then(res => res.json())
                    .then((data) => {
                      console.log('This is your data', data)
                      this.questions = data;
                      this.qsetid = data[0].questionSetId;
                      this.setState({

                                    questionNumber: 0,
                                    numCorrect: 0,
                                    statusShown: false,
                                    currentQuestionCorrect: false,
                                    qdata: data

                      })
                    })
                    .catch(console.log);
	}

    handleClick(answer) {
        this.setState({ statusShown: true });

        const correctAnswer =
            this.questions[this.state.questionNumber].correctAnswer;

        answer === correctAnswer
            ? this.setStatus("correct")
            : this.setStatus("wrong");
    }

    setStatus(status) {
        if (status === "correct") {
            this.setState(state => ({
                numCorrect: state.numCorrect + 1,
                currentQuestionCorrect: true,
            }));
        } else {
            this.setState({ currentQuestionCorrect: false });
        }

        setTimeout(() => this.switchQuestion(), 1250);
    }

    switchQuestion() {
        this.setState(state => ({
            statusShown: false,
            questionNumber:
                state.questionNumber < 4 ? state.questionNumber + 1 : false,
        }));
    }

    render() {
        if (this.questions.length == 0) {
            return (
                <div>Loading...</div>
            );
        }

        if (this.state.questionNumber !== false) {
            const question = this.questions[this.state.questionNumber].question;
            const answers = this.questions[this.state.questionNumber].answers;

            return (
              <div>
                <div className="container">
                <div class="topnav">
                  <a class="active" href="/">Home</a>
                  <div class="topnav-right">
                    <a href="privacy">Privacy</a>
                    <a href="terms">Terms</a>
                  </div>
                </div>
                    <h1 className="text-2xl font-sans mt-2 md:mt-8 lg:mt-12 font-bold text-yellow-800   text-center">
                          CineBotz
                    </h1>
                    <div>
                        <Header>{this.tidyUp(question)}</Header>
                        <div className="flex justify-center mt-16">
                            <AnswerCard
                                answers={answers}
                                onClick={this.handleClick}
                            />
                        </div>
                    </div>
                </div>
                    {this.state.statusShown && (
                        <Status correct={this.state.currentQuestionCorrect} />
                    )}
              </div>
            );
        } else {
            return <EndQuiz numCorrect={this.state.numCorrect}  user={this.userid} qsetid={this.qsetid} qdata={this.state.qdata}/>;
        }
    }

    tidyUp(text) {
      var result = text;
      result = result.replaceAll("[[", " '")
      result = result.replaceAll("]]", "' ")
      result = result.replaceAll("<small>", "")
      result = result.replaceAll("</small>", "")
      result = result.replaceAll("<br>", " ")
      result = result.replaceAll("<br />", " ")
      result = result.replaceAll(", Is this ", ", is this ")
      return result;
    }
}

export const Desktop = (props) => { return new DesktopC(props)};