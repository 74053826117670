function Answer(props) {
    return (
        <div
            className="bg-cyan-100 dark:bg-cyan-900 mx-6 h-16 w-auto mb-4 rounded-md flex items-center"
            onClick={() => props.onClick(props.answer)}
        >
            <input
                type="radio"
                name="answer"
                value={props.answer}
                className="ml-5 dark:bg-cyan-800"
            />
            <label className="text-cyan-700 dark:text-cyan-400 text-lg ml-4">
                {tidyUp(props.answer)}
            </label>
        </div>
    );
}

function tidyUp(text) {
  var result = text;
  result = result.replaceAll("[[", " '")
  result = result.replaceAll("]]", "' ")
  result = result.replaceAll("<small>", "")
  result = result.replaceAll("</small>", "")
  result = result.replaceAll("<br>", " ")
  result = result.replaceAll("<br />", " ")
  result = result.replaceAll(/<ref [^>]+>(.*?)<\/ref>/g,"")
  result = result.replaceAll(/{{(.*?)}}/g,"")
  result = result.replaceAll(/<ref (.*?)\/>/g,"")
  return result;
}

function AnswerCard(props) {
    return (
        <div className="bg-cyan-50 dark:bg-cyan-800 shadow-lg dark:shadow-dark rounded-2xl min-w-80 w-148">
            <div className="pt-6 pb-2">
                {props.answers.map(answer => (
                    <Answer
                        key={answer}
                        answer={answer}
                        onClick={props.onClick}
                    />
                ))}
            </div>
        </div>
    );
}

export default AnswerCard;
