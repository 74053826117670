import React from "react";
import './App.css';

// Components
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { About } from './About';
import { Help } from './Help';
import { Terms } from './Terms';
import { Privacy } from './Privacy';
import { Song } from './Song';
import { Home } from './Home';
import Cookies from 'universal-cookie';
import uuid from 'react-uuid';


function App() {
  const cookies = new Cookies();


  function setUser(uid) {
    cookies.set('name', uid, { path: '/' , maxAge: 864000});
  }

              var existingId = cookies.get('name');
              if (existingId) {
                  console.log('ak-next'+existingId);
              } else {
                  setUser(uuid());
                  existingId = cookies.get('name');
                  console.log('ak-first'+existingId);
              }

  return (
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<Home user={cookies.get('name')}/>} exact/>
                    <Route path="/about" element={<About/>} />
                    <Route path="/help" element={<Help/>} />
                    <Route path="/privacy" element={<Privacy/>} />
                    <Route path="/terms" element={<Terms/>} />
                    <Route path="/song" element={<Song/>} />
                </Routes>
            </BrowserRouter>
  )
}

export default App;
