import React from 'react'
export const Privacy = () => {
    return (
        <div className="bg-yellow-50 dark:bg-yellow-800 shadow-lg dark:shadow-dark rounded-2xl min-w-80 w-148">
                <h1 className="text-lg font-sans mt-2 md:mt-8 lg:mt-12 font-bold text-yellow-800   text-center">
                      CineBotz
                </h1>
            <p>Our website, https://www.cinebotz.com/ , uses cookies to enhance your browsing experience and to better understand how you interact with our site. A cookie is a small text file that is stored on your device when you visit our website.

               We use cookies to remember your preferences, such as language and region, and to analyze traffic on our site. We also use cookies to show you personalized content and ads.

               You have the option to accept or decline cookies by modifying your browser settings. However, please note that declining cookies may limit your ability to use certain features on our site.

               We do not share or sell any personal information collected through cookies to third parties.

               We regularly review and update our privacy policy to ensure that we are transparent about our use of cookies and to comply with any relevant laws and regulations.

               If you have any questions or concerns about our use of cookies, please contact us at cinebotz@gmail.com </p>
        </div>
    )
}