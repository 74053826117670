import React, { useState } from 'react';
import './container.css';

class EndQuizC extends React.Component {

   tidyUp(text) {
      var result = text;
      result = result.replaceAll("[[", " '")
      result = result.replaceAll("]]", "' ")
      result = result.replaceAll("<small>", "")
      result = result.replaceAll("</small>", "")
      result = result.replaceAll("<br>", " ")
      result = result.replaceAll("<br />", " ")
      result = result.replaceAll(", Is this ", ", is this ")
      result = result.replaceAll(/<ref [^>]+>(.*?)<\/ref>/g,"")
     result = result.replaceAll(/{{(.*?)}}/g,"")
     result = result.replaceAll(/<ref (.*?)\/>/g,"")
      return result;
    }

    constructor(props) {
        super(props);
        this.userid = props.user;
        this.qsetid = props.qsetid;
        this.numCorrect = props.numCorrect;
        this.qdata = props.qdata;
        this.state = {
            qStats : []
        };
    }

    componentWillMount() {

                        const requestOptions = {
                            method: 'POST',
                            headers: {'Content-Type': 'application/json'},
                            body: JSON.stringify({
                                                     qsetid : this.qsetid,
                                                     userid : this.userid,
                                                     attempts : '1',
                                                     score : ((100 * this.numCorrect) / 5).toString(),
                                                     scorehealth : 'high'
                                                 })
                        };

        fetch('https://api.cinebotz.com/api/score', requestOptions)
                        .then(response => response.blob())
                        .then(data => {
                            console.log(data);
                            fetch('https://api.cinebotz.com/stats/'+this.userid)
                                                .then(nresponse => nresponse.json())
                                                .then(statData =>
                                                        {
                                                            console.log(statData);
                                                            this.setState({
                                                                qStats : statData
                                                            })
                                                        }).catch(console.log);
                        });

    }

    render() {
        var percentCorrect = (100 * this.numCorrect) / 5;

        let header;
        if (percentCorrect >= 60) {
            header = (
                <h1 className="text-lg font-bold text-yellow-800   text-center">
                    Well done! You got{" "}
                    <span className="text-green-500">{percentCorrect}%!</span>
                </h1>
            );
        } else {
            header = (
                <h1 className="text-lg font-bold text-yellow-800   text-center">
                    You got <span className="text-red-500">{percentCorrect}%.</span>{" "}
                    Better luck next time!
                </h1>
            );
        }
        let stats;
        stats = (
            <h1 className="text-base font-bold text-yellow-800   text-center ml-2 mr-2">
                <div className="text-lg font-bold my-2">
                    Your CineBotz Statistics
                </div>
                <table className="mx-44">
                <tr>
                    <td className="text-left ml-2 mr-2">Total quizzes taken</td>
                    <td></td>
                    <td className="text-center ml-2 mr-2"> {this.state.qStats.quizzes}</td>
                </tr>
                <tr>
                    <td className="text-left ml-2 mr-2">Average score</td>
                    <td></td>
                    <td className="text-center ml-2 mr-2">{this.state.qStats.average}</td>
                 </tr>
                <tr>
                    <td className="text-left ml-2 mr-2">Highest score</td>
                    <td></td>
                    <td className="text-center ml-2 mr-2">{this.state.qStats.highest}</td>
                 </tr>
                </table>
            </h1>
        );

        return (
        <div class="container">
            <div className="justify-center items-center h-screen">

                <table>
                          <tr>&nbsp;</tr>
                          <tr>
                            <label className="text-lg font-sans mt-2 md:mt-10 lg:mt-22 font-bold text-fuchsia-800 dark:text-fuchsia-300 text-center">
                                        Correct Answers
                            </label>
                          </tr>
                          <tr>&nbsp;</tr>
                          <tr>
                            <label className="text-base font-sans font-bold text-fuchsia-800 dark:text-fuchsia-300 text-center">
                                        {this.tidyUp(this.qdata[0].question)}
                            </label>
                          </tr>
                          <tr>
                              <label className="text-fuchsia-700 dark:text-fuchsia-400 text-base ml-4">
                                              {this.tidyUp(this.qdata[0].correctAnswer)}
                              </label>
                          </tr>
                          <tr>&nbsp;</tr>
                          <tr>
                            <label className="text-base font-sans font-bold text-fuchsia-800 dark:text-fuchsia-300 text-center">
                                        {this.tidyUp(this.qdata[1].question)}
                            </label>
                          </tr>
                          <tr>
                              <label className="text-fuchsia-700 dark:text-fuchsia-400 text-base ml-4">
                                              {this.tidyUp(this.qdata[1].correctAnswer)}
                              </label>
                          </tr>
                          <tr>&nbsp;</tr>
                          <tr>
                            <label className="text-base font-sans font-bold text-fuchsia-800 dark:text-fuchsia-300 text-center">
                                        {this.tidyUp(this.qdata[2].question)}
                            </label>
                          </tr>
                          <tr>
                              <label className="text-fuchsia-700 dark:text-fuchsia-400 text-base ml-4">
                                              {this.tidyUp(this.qdata[2].correctAnswer)}
                              </label>
                          </tr>
                          <tr>&nbsp;</tr>
                          <tr>
                            <label className="text-base font-sans font-bold text-fuchsia-800 dark:text-fuchsia-300 text-center">
                                        {this.tidyUp(this.qdata[3].question)}
                            </label>
                          </tr>
                          <tr>
                              <label className="text-fuchsia-700 dark:text-fuchsia-400 text-base ml-4">
                                              {this.tidyUp(this.qdata[3].correctAnswer)}
                              </label>
                          </tr>
                          <tr>&nbsp;</tr>
                          <tr>
                            <label className="text-base font-sans font-bold text-fuchsia-800 dark:text-fuchsia-300 text-center">
                                        {this.tidyUp(this.qdata[4].question)}
                            </label>
                          </tr>
                          <tr>
                              <label className="text-fuchsia-700 dark:text-fuchsia-400 text-base ml-4">
                                              {this.tidyUp(this.qdata[4].correctAnswer)}
                              </label>
                          </tr>
                    <tr>
                        <h1 className="text-lg font-bold text-yellow-800   text-center mt-2">
                            Your Score
                        </h1>
                    </tr>
                    <tr>
                        {header}
                    </tr>

                    <tr>
                    </tr>
                    <tr>
                        {stats}
                    </tr>
                    <tr>&nbsp;</tr>
                    <tr>
                        <h1 className="text-base font-bold text-yellow-800   text-center ml-2 mr-2">
                          Come again tomorrow for new set of questions!!
                        </h1>
                    </tr>
                    <tr>&nbsp;</tr>
                </table>
            </div>
          </div>
        );
    }
}

export const EndQuiz = (props) => { return new EndQuizC(props) };
